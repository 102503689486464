var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{attrs:{"width":"800"}},[_c('v-card-text',{staticClass:"pa-5"},[_c('v-row',{attrs:{"dense":"","align":"center","justify":"center"}},[_c('v-col',{staticClass:"d-flex justify-center",attrs:{"cols":"2","align-self":"center"}},[_c('v-img',{staticClass:"pa-0",attrs:{"contain":"","height":"70","src":_vm.item.image_data.src}})],1),_c('v-col',{attrs:{"cols":"10"}},[_c('v-row',{staticClass:"display-flex align-center justify-start",attrs:{"dense":""}},[_c('span',{staticClass:"font-weight-medium title primary--text ml-5"},[_vm._v(" "+_vm._s(_vm.item.name)+" ")]),_c('v-tooltip',{attrs:{"color":"primary","top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-chip',_vm._g({staticClass:"font-weight-bold white--text mx-2",attrs:{"label":"","small":"","color":_vm.item.color ? _vm.item.color : 'grey'},domProps:{"textContent":_vm._s(_vm.item.abbreviation)}},on))]}}])},[_c('span',[_c('p',[_c('strong',[_vm._v("Abbreviation:")]),_vm._v(" "+_vm._s(_vm.item.abbreviation)+" ")]),_c('p',[_c('strong',[_vm._v("Color:")]),_vm._v(" "+_vm._s(_vm.item.color)+" ")]),_c('div',{style:({
                  height: '10px',
                  'background-color': _vm.item.color
                })})])])],1),_c('v-row',{staticClass:"display-flex align-center justify-start",attrs:{"dense":""}},[_c('v-tooltip',{attrs:{"color":"primary","top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                var on = ref.on;
return [_c('div',_vm._g({},on),[_c('v-avatar',{staticClass:" ml-5 mr-1",attrs:{"size":"10","color":_vm.categoriesById[_vm.item.category].color}}),_c('p',{staticClass:"font-italic subheading text-capitalize",staticStyle:{"display":"inline"},domProps:{"innerHTML":_vm._s(_vm.categoriesById[_vm.item.category].name)}})],1)]}}])},[_c('span',[_c('p',{staticClass:"mb-1"},[_c('strong',[_vm._v("Category")])]),_c('v-avatar',{staticClass:"mr-1",attrs:{"size":"15","color":_vm.categoriesById[_vm.item.category].color}}),_vm._v(" "+_vm._s(_vm.categoriesById[_vm.item.category].name)+" ")],1)])],1)],1)],1),_c('v-row',{attrs:{"dense":"","justify":"end"}},[_c('v-col',{staticClass:"offset-2 text-left flex-grow-0 flex-shrink-1"},[_c('v-btn',{directives:[{name:"show",rawName:"v-show",value:(_vm.showDetailsBtn),expression:"showDetailsBtn"}],staticClass:"font-weight-bold primary--text",attrs:{"text":"","color":"primary"},on:{"click":function($event){_vm.showDetails = !_vm.showDetails}}},[_c('v-icon',{attrs:{"left":"","color":"primary"},domProps:{"textContent":_vm._s(_vm.showDetails ? 'mdi-menu-up' : 'mdi-menu-down')}}),_vm._v(" DETAILS")],1)],1),_c('v-col',{staticClass:"flex-grow-1 flex-shrink-0"}),_c('v-col',{staticClass:"text-right flex-grow-0 flex-shrink-1"},[_c('v-tooltip',{attrs:{"color":"primary","top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"text":"","icon":"","color":"primary"},on:{"click":_vm.showNotes}},on),[_c('v-icon',{attrs:{"color":"primary"}},[_vm._v("mdi-note-text-outline")])],1)]}}])},[_c('span',{domProps:{"innerHTML":_vm._s(
              _vm.hasNotes
                ? ("<strong>Last Note:</strong> <br />" + (_vm.notes[0].note))
                : 'Notes'
            )}})])],1),_c('v-col',{staticClass:"text-right flex-grow-0 flex-shrink-1"},[_c('v-tooltip',{attrs:{"color":"primary","top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
            var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"text":"","icon":""},on:{"click":_vm.showReservations}},on),[_c('v-badge',{attrs:{"color":"primary","content":_vm.reservations.length,"overlap":"","value":_vm.hasReservations}},[_c('v-icon',{attrs:{"color":_vm.hasReservations ? 'primary' : 'disabled'}},[_vm._v("mdi-calendar-clock")])],1)],1)]}}])},[_c('span',[_vm._v("View Reservations")])])],1)],1),(_vm.showDetails)?_c('v-row',{attrs:{"dense":"","justify":"start"}},[_c('v-col',{staticClass:"text-left pt-0",attrs:{"cols":"8","offset":"2"}},[_c('v-divider',{attrs:{"inset":""}})],1),_c('v-col',{staticClass:"text-left",attrs:{"cols":"8","offset":"2"}},[_c('p',{staticClass:"caption body-1 pa-2",domProps:{"innerHTML":_vm._s(_vm.item.description)}}),_c('customFieldsList',{attrs:{"items":_vm.item.custom_fields}})],1)],1):_vm._e(),_c('v-dialog',{attrs:{"persistent":"","max-width":"600","transition":"dialog-transition"},model:{value:(_vm.modal),callback:function ($$v) {_vm.modal=$$v},expression:"modal"}},[_c('v-card',{attrs:{"flat":""}},[_c(_vm.modalComp,_vm._b({key:_vm.modal + _vm.modalComp,tag:"component",on:{"close":function($event){_vm.modal = false}}},'component',_vm.modalCompData,false))],1)],1)],1),(_vm.withCardActions)?_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"text":"","color":"primary"},on:{"click":function($event){return _vm.$emit('close')}}},[_vm._v("CLOSE")])],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }